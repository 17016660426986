import React from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Banner from '../components/Banner'

const title = 'Consulting'

const Generic = props => (
  <Layout>
    <Helmet>
      <html lang="en" />
      <title>HOVR</title>
      <meta name="description" content={title} />
    </Helmet>
    <Banner
      bannerImage={props.data.bannerImage}
      bannerSubtitle="Experts in Hovercraft"
      bannerTitle="Consulting"
    />

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <p>
            HOVR is a full-service hovercraft consultancy based in the San
            Francisco Bay Area. We have experts on hovercraft, marine
            technologies, transportation, land use, and civil engineering. Our
            fast execution of comprehensive reports will get you the information
            you need when you need it.
          </p>
          <h2>Site Surveys</h2>
          <p>
            If you are a real estate developer or local government looking to
            incorporate hovercraft service into your shoreline development, HOVR
            can help you identify the best site for a hoverport. Our experts
            will ensure that your development can accept fast and efficient
            service from the water, as well as to connecting ground
            transportation. Our full-service reports provide you with everything
            you need to incorporate a hoverport into your master plan and
            prepare you for the environmental and planning review processes.
          </p>
          <h2>Regional Surveys</h2>
          <p>
            For those operating at a larger scale, we can provide regional
            surveys to select the best, first route to introduce hovercraft
            service to your community. While we are focused on our native SF Bay
            Area, our team has global experience in route selection and design.
            Our transportation planners can help assist your team with
            connecting transit integration, proper capacity planning, and much
            more.
          </p>
          <h2>Hoverport Design</h2>
          <p>
            Our civil engineering team and hovercraft experts work together to
            ensure that your hoverport can accommodate your service from its
            pilot stage through a full system build out. Our experienced team
            has what it takes to minimize environmental impacts on sensitive
            shorelines, without sacrificing transportation capacity. Whether you
            need a single-pad hoverport for a remote village or a central hub
            for your region's water transportation network, we can design a
            facility that will maximize passenger satisfaction and minimize
            operating costs.
          </p>
          <h2>Hovercraft Selection and Design</h2>
          <p>
            Through our global network of hovercraft designers and builders,
            HOVR can help you locate the right craft for your needs. We have
            personal experience with craft of all sizes – from small 2-4
            passenger craft up to 100 seat vessels. We connect you with the
            manufacturer who best matches your needs and budget. If the right
            craft doesn't exist, our experienced engineering staff will work
            with you to design a new model which exactly matches your needs. We
            then arrange for its construction here in California, or we can work
            with your preferred shipyard anywhere in the world.
          </p>
          <p>
            Don’t see what you need here? Drop us a line and we can let you know
            if what you need is within our capabilities. If it’s not, we will
            refer you to someone with the expertise you require.
          </p>
        </div>
      </section>
    </div>
  </Layout>
)

export default Generic

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { eq: "consulting.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
